import React from "react";
import { Header } from "../Header";

export const AccoutView = (props) => {
  return (
    <section>
      <div className="w-full">
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="md:w-1/2 tall-sm:w-full">
              <div>
                <h1 className="text-3xl font-bold m-10">Account</h1>
              </div>
              <div className="md:m-10 tall-sm:m-5">
                <div className="w-full h-60 bg-gray-300" />
              </div>
              <div className="md:m-10 tall-sm:m-5">
                <div className="w-full h-4 bg-gray-300" />
              </div>
              {/* Only in mobile */}
              <div className="md:hidden tall-sm:m-5">
                <div className="w-full h-4 bg-gray-300" />
              </div>
              <div className="md:hidden tall-sm:m-5">
                <div className="w-full h-4 bg-gray-300" />
              </div>
              <div className="md:hidden tall-sm:m-5">
                <div className="w-full h-4 bg-gray-300" />
              </div>
            </div>
            {/* Esto aqui el desktop */}
            <div className="md:w-1/2">
              <div className="md:m-10">
                <div className="w-full h-4 bg-white" />
              </div>
              <div className="md:m-10">
                <div className="w-full h-4 bg-gray-300" />
              </div>
              <div className="md:m-10">
                <div className="w-full h-4 bg-gray-300" />
              </div>
              <div className="md:m-10">
                <div className="w-full h-4 bg-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
