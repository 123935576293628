import React from "react";

export const OrderDiscount = (props) => {

  return (
    <section>
      <div className="w-full">
        <div className="flex justify-evenly space-x-2 ml-2">
          <div className="flex-grow h-12">
            <input
              type="text"
              class="h-14 w-[222px] pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
              placeholder="Discount code"
            />
          </div>
          <div className="flex-grow h-12">&nbsp;</div>
          <div className="flex-grow h-12">
            <button
              class="h-14 w-[122px] pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-red-500 font-bold  ">
                Apply
              </button>
          </div>
        </div>
      </div>
    </section>
  );
};
