import React from "react";
import { PaymentDetails } from "./PaymentDetails";
import { OrderSummary } from "./OrderSummary";
import { useSelector } from "react-redux";
 
export const CheckoutView = (props) => {
  const quantity = useSelector((state) => state.shop.quantity); 
  const plans = useSelector((state) => state.shop.plans); 

  return (
    <section>
      <div className="w-full h-[150vh] bg-green-50">
        <div className="flex flex-rows-2">
          <div className="w-1/2 bg-white m-10 border rounded-3xl">
            <PaymentDetails
              quantity={quantity}
            />
          </div>
          <div className="w-1/2">
            <OrderSummary
              quantity={quantity}
              plans={plans}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
