import React from "react";

export const OrderSteps = (props) => {

  return (
    <section>
      <div className="flex flex-row">
        {/* Step 1 */}
        <div className="flex flex-row m-10">
          <div class="w-10 h-10 bg-purple-100 rounded-full flex justify-center items-center">
            <i class="fa fa-shopping-cart text-purple-600"></i>
          </div>
          <div className="flex flex-col">
            <h1 className="font-normal text-gray-400 text-sm">Step 1</h1>
            <h1 className="font-normal text-gray-400">Shop</h1>
          </div>
        </div>
        {/* Step 2 */}
        <div className="flex flex-row  m-10">
          <div class="w-10 h-10 bg-orange-100 rounded-full flex justify-center items-center">
            <i class="fa fa-money-bill text-gray-600"></i>
          </div>
          <div className="flex flex-col">
            <h1 className="font-normal text-gray-400 text-sm">Step 2</h1>
            <h1 className="font-bold text-gray-400">Payment</h1>
          </div>
        </div>
        {/* Step 3 */}
        <div className="flex flex-row  m-10">
          <div class="w-10 h-10 bg-green-100 rounded-full flex justify-center items-center">
            <i class="fa fa-envelope-open text-gray-600"></i>
          </div>
          <div className="flex flex-col">
            <h1 className="font-normal text-gray-400 text-sm">Step 3</h1>
            <h1 className="font-normal text-gray-400">Check your email</h1>
          </div>
        </div>
      </div>
    </section>
  );
};
