import { Link } from "react-router-dom";
import { SearchDestination } from "./SearchDestination";
import { API_BASE_URL } from "./network/API_BASE_URL";
export const Search = () => {
  return (
    <section
      className="w-full h-[341px] flex flex-col place-content-center gap-4 bg-searchHome"
      style={{backgroundPosition: "center center"}}
    >
      <div className="grid grid-cols-2 grid-row">
        <div className="w-1/2">
            {/* <ProductHomeDetails/> */}
        </div>
        <div className="w-1/2">
          <Link to="/shop">
            <SearchDestination/>
          </Link>
        </div>
      </div>
    </section>
  );
};
