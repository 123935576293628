import React from "react";
import { useParams } from "react-router-dom";

export const OrderSubtotal = (props) => {
  const { id } = useParams();

  console.log(props.plans);
  let filteredData = props.plans.filter(
    (data, index) =>
      data.description.toLowerCase().indexOf(id.toLowerCase()) !== -1
  );
  console.log(filteredData);

  let country;
  let price;
  let volumen;
  let days;
  if (filteredData.length > 0) {
    country = filteredData[0].category;
    volumen = filteredData[0].company;
    days = filteredData[0].company2;
    price = filteredData[0].price;
  }

  return (
    <section>
      <div className="w-full">
        <div className="flex flex-col">
          {/* Item #1 */}
          <div className="flex justify-evenly space-x-2 ml-2 mt-3 ">
            <div className="flex-grow">
              <div className="flex flex-col">
                <div className="">
                  <h1 className="font-bold">{country}</h1>
                </div>
                <div>
                  <div className="flex flex-row">
                    <div className="ml-10 text-sm">{volumen}</div>
                    <div className="ml-10 text-sm">{days}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow-0">&nbsp;</div>
            <div className="flex-grow justify-end">
              <h1>{"US$" + price}</h1>
            </div>
          </div>
          {/* Item #2 */}
          <div className="flex justify-evenly space-x-2 ml-2 mt-3 ">
            <div className="flex-grow">
              <div className="flex flex-col">
                <div className="">
                  <h1>{"Discount"}</h1>
                </div>
              </div>
            </div>
            <div className="flex-grow">&nbsp;</div>
            <div className="flex-grow justify-end">
              <h1>{"$0.00"}</h1>
            </div>
          </div>
          {/* Item #3 */}
          <div className="flex justify-evenly space-x-2 ml-2 mt-3 ">
            <div className="flex-grow">
              <div className="flex flex-col">
                <div className="">
                  <h1>{"Quantity"}</h1>
                </div>
              </div>
            </div>
            <div className="flex-grow">&nbsp;</div>
            <div className="flex-grow justify-end">
              <h1>{props.quantity}</h1>
            </div>
          </div>
          {/* Item #4 */}
          <div className="flex justify-evenly space-x-2 ml-2 mt-3 ">
            <div className="flex-grow">
              <div className="flex flex-col">
                <div className="">
                  <h1>{"Subtotal"}</h1>
                </div>
              </div>
            </div>
            <div className="flex-grow">&nbsp;</div>
            <div className="flex-grow justify-end">
              <h1>{price*props.quantity}</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
