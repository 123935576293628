import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlansData } from "../redux/actions/shopActions";
import { API_BASE_URL } from "../network/API_BASE_URL";

const SkeletonItem = () => {
  const [isShimmering, setIsShimmering] = useState(false);

  useEffect(() => {
    setIsShimmering(true);
    return () => {
      setIsShimmering(false);
    };
  }, []);

  return (
    <div className="skeleton-item">
      <div className={`skeleton-rect ${isShimmering ? "shimmer" : ""}`}></div>
    </div>
  );
};

export const ShopSearch = ({props, translation}) => {

  const { id } = useParams();
  const [searchText, setSearchText] = useState("");

  const plans = useSelector((state) => state.shop.plans);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlansData());
  }, [dispatch]);

  const onHandleEventFirebase = ( () => {
    let newEvent = {
      "name": "react_shop_search_selected",
      "action": "click",
      "button": "image_selected",
      "id": id,
      "view": "ShopSearch"
    }
    // props.analytics.logEvent(newEvent, "react_shop_search_image_selected");
  })

  let filteredData = plans.filter(
    (data, index) =>
      data.category.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
  );

  let price
  if(filteredData.length > 0){

    price = Math.round(
      filteredData[0].priceesim / 10000 +
        (filteredData[0].priceesim / 10000) * (80 / 100)
    ).toFixed(2);

  }

  const onChangeField = (event) => {
    setSearchText(event.target.value);
  };

  const { t } = translation;

  return (
    <section>
      <div className="w-full">
        <div className="*:flex *:flex-rows-2">
          <input
            className="w-[80%] text-5xl m-10 text-gray-500 font-semibold"
            type="text"
            name="search"
            id="description"
            placeholder={t("shop_view_label_first")}
            onChange={onChangeField}
          />
          <div className="flex flex-wrap">
            {filteredData ? (
              filteredData.map((plan, index) => (
                <Link
                  onClick={onHandleEventFirebase}
                  className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3"
                  to={"/shop/" + plan.description}
                  key={index}
                >
                  <div>
                    <div className="bg-white m-6 border rounded-3xl overflow-hidden shadow">
                      <div className="flex flex-col">
                        <div className="shadow static">
                          <img
                            src={
                              API_BASE_URL + "/images/" + plan.category + ".jpg"
                            }
                            alt={plan.category}
                          />
                          <div className="hidden lg:w-[20%] lg:absolute">
                            <div className="justify-end flex">
                              <img
                                width={"50px"}
                                height={"50px"}
                                src={ plan.flagcode ? 
                                  `https://p.qrsim.net/img/flags/${plan.flagcode.toLowerCase()}.png`
                                 : "https://esimflys.com:8443/images/logo-transparent.png"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="p-6">
                          <h4 className="font-bold text-gray-600">
                            {plan.category}
                          </h4>
                          <div className="text-gray-400">{t("shop_view_label_second")}</div>
                          <div className="flex flex-row">
                            <div className="font-bold text-gray-600">
                              {"US$" + price}
                            </div>
                            &nbsp;
                            <div className="text-sm text-gray-400 justify-center items-center">
                              {plan.company2}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <div className="w-full">
                <div>
                  <div className="grid grid-cols-3 mt-10">
                    {Array.from({ length: 12 }, (_, index) => (
                      <SkeletonItem key={index} />
                    ))}
                  </div>
                  <div className="grid grid-cols-3 mt-10">
                    {Array.from({ length: 12 }, (_, index) => (
                      <SkeletonItem key={index} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
