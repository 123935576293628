import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { OrderSteps } from "./orders/OrderSteps";
import { OrderDiscount } from "./orders/OrderDiscount";
import { OrderPersonalInfo } from "./orders/OrderPersonalInfo";
import { OrderSubtotal } from "./orders/OrderSubtotal";
import { useSelector } from "react-redux";
 
export const OrderSummary = (props) => {
  const { id } = useParams();
  const quantity = useSelector((state) => state.shop.quantity); 

  let filteredData = props.plans.filter(
    (data, index) =>
      data.description.toLowerCase().indexOf(id.toLowerCase()) !== -1
  );

  let price
  if(filteredData.length > 0){
    price = filteredData[0].price
  }

  return (
    <section>
      <div className="w-full ">
        <div className="flex flex-col">
          <div className="m-10">
            <h1 className="font-bold text-3xl">Order summary</h1>
          </div>
          <div>
            <OrderSteps />
          </div>
          <div>
            <div className="max-w-md ml-10 justify-start items-start">
              <CollapsibleItem title="Discount">
                <OrderDiscount/>
              </CollapsibleItem>
              <CollapsibleItem title="Personal Information">
                <OrderPersonalInfo/>
              </CollapsibleItem>
              <CollapsibleItem title="Subtotal">
                <OrderSubtotal
                  plans={props.plans}
                  quantity={quantity}
                />
              </CollapsibleItem>
            </div>
          </div>
          <div className="mt-10">
            <div className="flex justify-evenly space-x-2 ml-12 mr-12">
              <div className="flex-grow">
                <h1>Total amount: </h1>
              </div>
              <div className="flex-grow-0">&nbsp;</div>
              <div className="flex-grow">
                <h1 className="font-bold text-2xl">{"$"+price*props.quantity}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CollapsibleItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-white rounded-lg mb-4">
      <div
        className="border-b p-4 cursor-pointer flex justify-between items-center bg-white border rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-lg font-semibold">{title}</h2>
        <i className={`fa ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`} />
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};
