import { Header } from "./Header";
import { ShopSearch } from "./shop/ShopSearch";
import { ShopSearchSideBar } from "./shop/ShopSearchSideBar";

export const Shop = ({props, translation}) => {

  const { t } = translation;

  return (
    <section>
      <div className="flex flex-row lg:flex lg:flex-row">
          <div className="hidden lg:w-1/4 lg:block">
            <ShopSearchSideBar translation={translation} />
          </div>
          <div className="w-full lg:w-3/4">
          <ShopSearch
            translation={translation} 
            // analytics={props.analytics}
          />
          </div>
      </div>
    </section>
  );
};
