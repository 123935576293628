import { Link } from "react-router-dom";
export const FrontPage = ({ translation }) => {

  const { t } = translation;

  const onHandleEventFirebase = ( () => {
    let newEvent = {
      "name": "react_shop_now_button",
      "action": "click",
      "button": "shop_button",
      "view": "Home"
    }
    // props.analytics.logEvent(newEvent, "react_shop_now_button");
    // props.analytics.logEvent(newEvent, "react_shop_now_button", { debug_mode: true });
  })
  return (
    <section className="w-full h-[85vh] max-h-[800px] flex items-center justify-center px-8 md:px-21 bg-mobile-front sm:bg-desktop-front bg-cover bg-center">
      <div className="text-center text-black">
        <h1 className="text-2xl font-semibold text-black">
          {t("home_view_label_first")} #summersalediscount,
        </h1>
        <p className="text-2xl font-semibold text-black">
          {t("home_view_label_second")}
        </p>
        <Link
          onClick={onHandleEventFirebase}
          to="/shop"
          className="inline-block mt-4 px-9 py-3 rounded-3xl bg-secondary-400 font-semibold text-white text-lg"
        >
          {t("home_view_label_third")}
        </Link>
      </div>
    </section>
  );
};
