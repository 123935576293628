import { Header } from "./components/Header";
import { Home } from "./root/Home";
import { Switch, Route } from "react-router-dom";
import { FirebaseConfig } from "./FirebaseConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { HelpView } from "./components/landing/HelpView";
import { SignUpView } from "./components/landing/SignUp";
import { AboutUsView } from "./components/landing/AboutUsView";
import { SignInView } from "./components/landing/SignIn";
import { MessageView } from "./components/login/Message";
import { AccoutView } from "./components/login/AccountView";
import { ShopSearchDetails } from "./components/shop/ShopSearchDetails";
import { Shop } from "./components/Shop";
import { PaypalConfirmationView } from "./components/checkout/PaypalConfirmationView";
import { CheckoutView } from "./components/checkout/CheckoutView";
import { useTranslation } from "react-i18next";
import { Footer } from "./components/Footer";
import { Rights } from "./components/Rights";
import { CancellationView } from "./components/landing/CancellationView";
import { BlogView } from "./components/landing/BlogView";

firebase.initializeApp(FirebaseConfig);

const analytics = firebase.analytics();


const App = () => {

  const { t, i18n } = useTranslation();

  return (
    <section>
      <Header translation={{t,i18n}} analytics={analytics} />
      <div>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Home translation={{ t }} />
            )}
          />
          <Route
            exact
            path="/help"
            render={(props) => (
              <HelpView translation={{ t }}/>
            )}
          />
          <Route
            exact
            path="/cancellation"
            render={(props) => (
              <CancellationView translation={{ t }}/>
            )}
          />
          <Route
            exact
            path="/signup"
            render={(props) => (
              <SignUpView />
            )}
          />
          <Route
            exact
            path="/about"
            render={(props) => (
              <AboutUsView translation={{ t }} />
            )}
          />
          <Route
            exact
            path="/login"
            render={(props) => (
              <SignInView analytics={analytics} />
            )}
          />
          <Route
            exact
            path="/account"
            render={(props) => (
              <AccoutView />
            )}
          />
          <Route
            exact
            path="/message"
            render={(props) => (
              <MessageView />
            )}
          />
          <Route
            exact
            path="/shop"
            render={(props) => (
              <Shop analytics={analytics} translation={{ t }} />
            )}
          />
          <Route
            exact
            path="/shop/:id"
            render={(props) => (
              <ShopSearchDetails analytics={analytics} translation={{ t }} />
            )}
          />
          <Route
            exact
            path="/paypalsuccess/:id"
            render={(props) => (
              <PaypalConfirmationView analytics={analytics} />
            )}
          />
          <Route
            exact
            path="/blog"
            render={(props) => (
              <BlogView translation={{ t }}/>
            )}
          />
          <Route
            exact
            path="/checkout/:id"
            render={(props) => (
              <CheckoutView />
            )}
          />
        </Switch>
        <Footer/>
        <Rights/>
      </div>
    </section>
  );
}

export default App;
