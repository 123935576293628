import React, { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios"; // Import Axios for making HTTP requests

export const PaymentDetails = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const email = useSelector((state) => state.shop.email);

  const handleCancelOrder = () => {
    setShowModal(true);
  };

  const confirmCancelOrder = () => {
    setShowModal(false);
    window.history.back();
  };

  const cancelCancelOrder = () => {
    setShowModal(false);
  };

  const handleCancelOrderSuccess = () => {

    console.log("test")
    axios.post("http://localhost:8443/checkout", { email })
      .then((response) => {
        // Handle success response
        // handleCancelOrderSuccess();
        console.log(response.data);
        if(response.data.result === "success"){

          setShowModalSuccess(true);
        }else{
          
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming order:", error);
      });

    // setShowModalSuccess(true);
  };

  const confirmCancelOrderSuccess = () => {
    setShowModalSuccess(false);
    window.location.href = "/";

  };

  return (
    <section>
      <div className="w-full">
        <div className="flex flex-col">
          <div>
            <div className="flex flex-row">
              <div className="ml-10">
                <img className="w-[5px] h-[10vh] items-start justify-start px-8 bg-logo bg-cover bg-center" />
              </div>
              <div className="mt-10">
                <h1 className="font-bold">eSIMFLYS</h1>
              </div>
            </div>
          </div>
          <div className="ml-10 mt-1 bg-white">
            <div className="p-10">
              <h1 className="text-3xl font-semibold">{"Payment Details"}</h1>
            </div>
          </div>
          <div className="flex flex-col">
            <div>
              <div class="flex ml-10">
                <h1 className="text-sm text-gray-500">Credit Card</h1>
              </div>
              <div class="flex ml-10 mb-7">
                <div class="relative">
                  <input
                    type="text"
                    class="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-gray-100"
                    placeholder="Credit Card"
                  />
                  <div class="absolute top-1 right-3">
                    <img className="w-[8px] h-[7vh] px-8 bg-mastercard sm:bg-mastercard bg-cover bg-center" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex ml-10">
              <h1 className="text-sm text-gray-500">Name</h1>
            </div>
            <div class="flex ml-10 mb-7">
              <div class="relative">
                <input
                  type="text"
                  class="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-gray-100"
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="flex flex-rows-2">
              <div>
                <div class="flex ml-10">
                  <h1 className="text-sm text-gray-500">Expiration Date</h1>
                </div>
                <div class="flex ml-10 mb-7">
                  <div class="relative">
                    <input
                      type="text"
                      class="h-14 w-[122px] pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-gray-100"
                      placeholder="MM/YY"
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="flex ml-10">
                  <h1 className="text-sm text-gray-500">CVV</h1>
                </div>
                <div class="flex ml-10 mb-7">
                  <div class="relative">
                    <input
                      type="text"
                      class="h-14 w-[122px] pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-gray-100"
                      placeholder="CVV"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-rows-2">
              <div>
                <div className="flex ml-10 mb-7">
                  <div className="relative">
                    <button
                      className="h-14 w-[222px] pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-black text-white"
                      onClick={handleCancelOrderSuccess}
                    >
                      Confirm Order
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex ml-10 mb-7">
                  <div className="relative">
                    {/* Call handleCancelOrder function onClick */}
                    <button
                      className="h-14 w-[222px] pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-gray-200 text-gray-500"
                      onClick={handleCancelOrder}
                    >
                      Cancel Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal for confirmation */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
            <p>Are you sure you want to cancel your order?</p>
            <div className="mt-4 flex justify-between">
              <button
                className="bg-red-500 text-white px-10 py-2 rounded"
                onClick={confirmCancelOrder}
              >
                Yes
              </button>
              <button
                className="bg-gray-300 text-gray-700 px-10 py-2 rounded"
                onClick={cancelCancelOrder}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modal for confirmation Success */}
      {showModalSuccess && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg">
            <div>
              <div className="flex flex-col justify-center items-center">
                <div className="text-7xl text-green-500">
                  <i className="fa fa-check-circle" />
                </div>
                <div className="text-2xl font-normal text-gray-600 m-5">
                  <h1>Payment Complete</h1>
                </div>
                <div className="justify-center text-center">
                  <div className="font-normal text-gray-600">
                    <h1>Thank you, your payment has been</h1>
                  </div>
                  <div className="font-normal text-gray-600">
                    <h1>successful. Your eSIM Card has been </h1>
                  </div>
                  <div className="flex flex-row justify-center text-center">
                    <div className="font-normal text-gray-600">
                      <h1> sent to</h1>
                    </div>
                    <div className="pl-1 font-bold text-black">
                      <h1>{email}</h1>
                    </div>
                  </div>
                  <div className="flex flex-row mt-10 justify-start text-start">
                    <div className="pl-1  font-bold text-black">
                      <h1>{"ORDER DETAILS"}</h1>
                    </div>
                  </div>
                  <div className="flex flex-row mt-4 justify-start text-start">
                    <div className="pl-1  font-bold text-red-900">
                      <div className="border bg-red-900 border-red-900 bg rounded-3xl h-1 w-[125px]"></div>
                    </div>
                  </div>
                  <div className="flex flex-row">

                    <div className="flex flex-row mt-10 justify-start text-start">
                      <div className="pl-1 text-xs font-bold text-black">
                        <h1>{"ORDER NUMBER:"}</h1>
                      </div>
                    </div>
                    <div className="flex flex-row mt-10 justify-start text-start">
                      <div className="pl-10 text-xs font-bold text-gray-500">
                        <h1>{"0003"}</h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row">

                    <div className="flex flex-row mt-5 justify-start text-start">
                      <div className="pl-1 text-xs font-bold text-black">
                        <h1>{"ORDER DATE:"}</h1>
                      </div>
                    </div>
                    <div className="flex flex-row mt-5 ml-5 mb-10 justify-start text-start">
                      <div className="pl-10 text-xs font-bold text-gray-500">
                        <h1>{"Dec 7, 2024, 11:52"}</h1>
                      </div>
                    </div>
                  </div>

                  <button
                    className="bg-gray-300 text-gray-700 px-10 py-2 rounded m-5"
                    onClick={confirmCancelOrderSuccess}
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
