import React from "react";
import { Header } from "../Header";

export const MessageView = (props) => {
  return (
    <section>
      <div className="w-full">
        <div className="flex flex-col">
          <div className="justify-center m-10">
            <h1 className="text-3xl font-bold">Message</h1>
          </div>
          <div className="flex flex-row">
            <div className="w-3/4">
              <div className="m-10">
                <div className="w-[90%] h-80 bg-gray-300" />
              </div>
              <div className="m-10">
                <div className="w-[90%] h-10 bg-gray-300" />
              </div>
            </div>
            <div className="w-1/3">
              <div className="m-10">
                <div className="w-[50%] h-10 bg-gray-300" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
