import React from "react";
import { useDispatch } from "react-redux";
import { setEmail } from "../../redux/actions/shopActions";

export const OrderPersonalInfo = (props) => {

  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    console.log(event.target.value);
    dispatch(setEmail(event.target.value));
  };

  return (
    <section>
      <div className="w-full">
        <div className="flex flex-col">
          {/* Email field */}
          <div>
            <div class="flex ml-2">
              <h1 className="text-sm text-gray-500 font-normal">Email</h1>
            </div>
            <input
              type="text"
              class="h-14 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
              placeholder="Email Name"
              onChange={handleEmailChange}
            />
          </div>
          {/* First Name field */}
          <div>
            <div class="flex ml-2">
              <h1 className="text-sm text-gray-500 font-normal">First Name</h1>
            </div>
            <input
              type="text"
              class="h-14 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
              placeholder="First Name"
            />
          </div>
          {/* Last Name field */}
          <div>
            <div class="flex ml-2">
              <h1 className="text-sm text-gray-500 font-normal">Last Name</h1>
            </div>
            <input
              type="text"
              class="h-14 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
              placeholder="Last Name"
            />
          </div>
          <div className="flex justify-between gap-2">
            {/* Country field */}
            <div>
              <div class="flex ml-2">
                <h1 className="text-sm text-gray-500 font-normal">Country</h1>
              </div>
              <input
                type="text"
                class="h-14 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
                placeholder="Country"
              />
            </div>
            {/* City field */}
            <div>
              <div class="flex ml-2">
                <h1 className="text-sm text-gray-500 font-normal">City</h1>
              </div>
              <input
                type="text"
                class="h-14 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
                placeholder="City"
              />
            </div>
          </div>
          {/* Phone number field */}
          <div>
            <div class="flex ml-2">
              <h1 className="text-sm text-gray-500 font-normal">Phone number</h1>
            </div>
            <input
              type="text"
              class="h-14 w-full pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none bg-white"
              placeholder="Phone number"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
