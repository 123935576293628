import { Header } from "../Header";
import { API_BASE_URL } from "../network/API_BASE_URL";
import axios from "axios";
import { useState } from "react";

export const SignUpView = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const onSubmitSignUp = (event) => {
    event.preventDefault(); // Prevents default form submission behavior

    axios
      .post(`${API_BASE_URL}/createuser`, { email, password })
      .then((response) => {
        console.log(response.data);
        setEmail("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        console.error("Error confirming order:", error);
      });
  };

  return (
    <section>
      <form onSubmit={onSubmitSignUp}>
        <div
          className="w-full h-screen flex"
          style={{
            backgroundImage: `url(${API_BASE_URL}/images/SignIn.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
        >
          <div className="flex flex-col">
            <div className="justify-start items-start text-start mt-10 ml-20">
              <h1 className="text-white text-5xl font-bold">Welcome</h1>
            </div>
            <div className="justify-start items-start text-start ml-20">
              <h1 className="text-white text-xl font-bold">
                Sign up to your account
              </h1>
            </div>
            <div className="justify-start items-start text-start mt-5 ml-10">
              <div>
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="relative">
                    <input
                      type="text"
                      className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="absolute top-4 right-3">
                      <i className="fa fa-envelope text-gray-400 z-20 hover:text-gray-500"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-start items-start text-start mt-5 ml-10">
              <div>
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="relative">
                    <input
                      type="password"
                      className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="absolute top-4 right-3">
                      <i className="fa fa-lock text-gray-400 z-20 hover:text-gray-500"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-start items-start text-start mt-5 ml-10">
              <div>
                <div className="px-4 sm:px-6 lg:px-8">
                  <div className="relative">
                    <input
                      type="password"
                      className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <div className="absolute top-4 right-3">
                      <i className="fa fa-lock text-gray-400 z-20 hover:text-gray-500"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-5 ml-20">
                <button type="submit" className="w-[80%]">
                  <div className="shadow border rounded-xl bg-yellow-400">
                    <div className="justify-center items-center">
                      <div className="p-5 text-xl font-semibold">
                        {"Sign Up"}
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};
