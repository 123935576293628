import { useEffect } from "react";
import { Header } from "../Header";
import { ShopSearchDetailsList } from "./ShopSearchDetailsList";
import { ShopSearchDetailsPlan } from "./ShopSearchDetailsPlans";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlansData } from "../redux/actions/shopActions";

export const ShopSearchDetails = ({translation}) => {
  const plans = useSelector((state) => state.shop.plans);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPlansData());
  }, [dispatch]);

  return (
    <section>
      <div className="flex flex-row lg:flex lg:flex-row">
        <div className="hidden lg:w-1/2 lg:block">
          <ShopSearchDetailsList translation={translation} plans={plans} />
        </div>
        <div className="w-full lg:w-1/2">
          <ShopSearchDetailsPlan translation={translation} plans={plans} />
        </div>
      </div>
    </section>
  );
};
