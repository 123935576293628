import { Header } from "../Header";

export const HelpView = ({translation}) => {

  const { t } = translation;

  return (
    <section>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-md">
        <div className="flex items-center justify-center mb-6">
          <i className="text-orange-600 mr-2 text-4xl fa fa-question-circle" />
          <h1 className="text-2xl font-bold text-gray-800">{t("help_view_label_first")}</h1>
        </div>
        <p className="text-gray-600 mb-4">
          {t("help_view_label_second")}
        </p>
        <p className="text-gray-600 mb-4">
          {t("help_view_label_third")}
        </p>
        <ul className="list-disc list-inside mb-4 text-gray-600">
          <li>{t("help_view_label_fourth")}</li>
          <li>{t("help_view_label_fifth")}</li>
          <li>{t("help_view_text_first")}</li>
          <li>{t("help_view_label_sixth")}</li>
          <li>{t("help_view_label_seventh")}</li>
        </ul>
        <p className="text-gray-600 mb-4">
          {t("help_view_label_eighth")} <a className="text-blue-700" style={{'text-decoration':'underline'}} href="mailto:help@esimflys.com">help@esimflys.com.</a>
        </p>
        <p className="text-gray-600 mb-4">
          {t("help_view_label_nineth")}
        </p>
      </div>
    </div>
    </section>
  );
};
