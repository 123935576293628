import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const Footer = () => {
  return (
    <section className="mx-6 xl:my-6">
      <div className="w-full h-auto grid grid-cols-1 grid-rows-[auto_auto_auto] gap-11 xl:grid-cols-3 xl:grid-rows-1">
        <div className="w-full h-[226px] grid grid-cols-[60%_40%] grid-rows-1 justify-start items-start">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <div className="mb-5">
              <h2 className="text-sm font-bold text-gray-600">Support</h2>
            </div>
            <Link to="/help">
              <h2 className="text-sm font-normal">Help Center</h2>
            </Link>
            <Link to="/cancellation">
              <h2 className="text-sm font-normal">Cancellation</h2>
            </Link>
            <h2 className="text-sm font-normal">Refund policies</h2>
            <h2 className="text-sm font-normal">Report issues</h2>
            <Link to="/blog">
              <h2 className="text-sm font-normal">Blog</h2>
            </Link>
          </div>
        </div>
        <div className="w-full h-[226px] grid grid-cols-[60%_40%] grid-rows-1 justify-start items-start">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <div className="mb-5">
              <h2 className="text-sm font-bold text-gray-600">eSIM</h2>
            </div>
            <h2 className="text-sm font-normal">What is an eSIM</h2>
            <div className="text-sm font-normal">
              <div>eSIM Compatible</div> 
              <div>devices</div> 
            </div>
            <div className="text-sm font-normal">
              <div>How to install and</div> 
              <div>activate your eSIM</div> 
            </div>
            <h2 className="text-sm font-normal">FAQs</h2>
          </div>
        </div>
        <div className="w-full h-[226px] grid grid-cols-[60%_40%] grid-rows-1 border-b-[0.3px] border-gray-300 xl:border-r-[0.3px] xl:border-b-0">
          <div className="flex flex-col items-start justify-start w-full gap-1">
            <div className="mb-5">
              <h2 className="text-sm font-bold text-gray-600">eSIMFlys</h2>
            </div>
            <h2 className="text-sm font-normal">Newsroom</h2>
            <div className="text-sm font-normal">
              <div>New features</div> 
              <div>Careers</div> 
            </div>
            <div className="text-sm font-normal">
              <div>Investors</div> 
              <div>Gift cards</div> 
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
