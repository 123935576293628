export const BlogView = ({ translation }) => {
  const { t } = translation;

  return (
    <section>
      <div className="w-full m-10">
        <div className="hidden lg:flex lg:flex-row">
          <div className="lg:w-3/4">
            <div className="lg:content-center text-center">
              <h1 className="text-3xl font-bold">Novedades mas recientes</h1>
            </div>
            <div className="lg:content-center lg:text-center lg:mt-10 lg:mb-5">
              <h1 className="">
                Descubre cómo usar internet fuera de tu país: activación,
                operadores, tarifas y opciones alternativas.
              </h1>
            </div>
            <div className="lg:flex lg:flex-row">
              <div className="lg:w-1/2 lg:m-10">
                <div className="lg:content-center lg:text-center lg:mt-10 lg:mb-5">
                  <h1 className="text-black font-bold text-md text-start">
                    Chip para Colombia Mejor chip para Colombia: ¡Compara
                    opciones y precios!
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start">
                  Encuentra el mejor chip para Colombia (físico o virtual). Comparamos: Holafly con datos ilimitados, Movistar, Claro, Tigo y WOM, ¡Tú...
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start underline">
                    Leer mas
                  </h1>
                </div>
              </div>
              <div className="lg:w-1/2 lg:m-10">
                <div className="lg:content-center lg:text-center lg:mt-10 lg:mb-5">
                  <h1 className="text-black font-bold text-md text-start">
                  Itinerancia de datos: Qué es y cómo activarla en iPhone y Android                  
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start">
                  La itinerancia de datos te permite tener internet en el extranjero. Descubre qué es, como activarla, operadores, tarifas y alternativas....
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start underline">
                  Leer mas
                  </h1>
                </div>
              </div>
            </div>
            <div className="lg:flex lg:flex-row">
              <div className="lg:w-1/2 lg:m-10">
                <div className="lg:content-center lg:text-center lg:mt-10 lg:mb-5">
                  <h1 className="text-black font-bold text-md text-start">
                  Mejores planes prepago eSIM mundial (2024)
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start">
                  Descubre cómo funciona la eSIM Mundial, precio, datos ilimitados y beneficios con Holafly y otras opciones como Ubigi, Nomad y MXT...                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start underline">
                    Leer mas
                  </h1>
                </div>
              </div>
              <div className="lg:w-1/2 lg:m-10">
                <div className="lg:content-center lg:text-center lg:mt-10 lg:mb-5">
                  <h1 className="text-black font-bold text-md text-start">
                  Las 9 mejores tarjetas SIM internacionales prepago 2024
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start">
                  9 Mejores tarjetas SIM internacionales prepago 2024 para viajar a Latinoamérica, Europa, Estados Unidos, Asia, Japón, Sudáfrica, y...
                  </h1>
                  <h1 className="text-gray-600 font-normal text-sm text-start underline">
                  Leer mas
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-1/4">
            <div className="lg:content-center">
              <h1 className="text-3xl font-bold">Most popular</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
