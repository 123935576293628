import React, { useState } from "react";
import { useEffect } from "react";
import { Header } from "../Header";
import { API_BASE_URL } from "../network/API_BASE_URL";
import "firebase/compat/auth";
import "firebase/compat/analytics";

export const SignInView = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    // props.analytics.logEvent("sign_in", { method: "Google" });
    // props.analytics.logEvent("sign_in", { method: "Google_Debug_Mode_Me" }, { debug_mode: true });
  });

  const onSubmitSignUp = (event) => {
    event.preventDefault();
  };

  const handleSignIn = () => {
    const provider = new props.firebase.auth.GoogleAuthProvider();
    props.firebase.auth().signInWithPopup(provider);

    props.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            // console.log("User token:", token);
            // console.log("User token:", user.displayName);
            // console.log("User token:", user.email);
            // console.log("User token:", user.emailVerified);
            // console.log("User token:", user.phoneNumber);
            // console.log("User token:", user.photoURL);
            // console.log("User token:", user.providerData);
            localStorage.setItem("token", token);
            localStorage.setItem("username", user.displayName);
            localStorage.setItem("email", user.email);
            window.location.href = "/";
            setTimeout(() => {
              window.location.reload();
            }, 500);
          })
          .catch((error) => {
            console.error("Error getting user token:", error);
          });
      } else {
        console.log("User is signed out");
      }
    });
  };

  return (
    <section>
      <div
        className="w-full h-screen flex"
        style={{
          backgroundImage: `url(${API_BASE_URL}/images/SignIn.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <div className="flex flex-col">
          <div className="justify-start items-start text-start m-10"></div>
          <div className="justify-start items-start text-start ml-10">
            <form onSubmit={onSubmitSignUp}>
              <div
                className="w-full h-screen flex"
                style={{
                  backgroundImage: `url(${API_BASE_URL}/images/SignIn.png)`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundAttachment: "fixed",
                }}
              >
                <div className="flex flex-col">
                  <div className="justify-start items-start text-start mt-10 ml-20">
                    <h1 className="text-white text-5xl font-bold">Welcome</h1>
                  </div>
                  <div className="justify-start items-start text-start ml-20">
                    <h1 className="text-white text-xl font-bold">
                      Sign in to your account
                    </h1>
                  </div>
                  <div className="justify-start items-start text-start mt-5 ml-10">
                    <div>
                      <div className="px-4 sm:px-6 lg:px-8">
                        <div className="relative">
                          <input
                            type="text"
                            className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                            placeholder="Email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <div className="absolute top-4 right-3">
                            <i className="fa fa-envelope text-gray-400 z-20 hover:text-gray-500"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-start items-start text-start mt-5 ml-10">
                    <div>
                      <div className="px-4 sm:px-6 lg:px-8">
                        <div className="relative">
                          <input
                            type="password"
                            className="h-14 w-96 pr-8 pl-5 rounded z-0 focus:shadow focus:outline-none"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="absolute top-4 right-3">
                            <i className="fa fa-lock text-gray-400 z-20 hover:text-gray-500"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="mt-5 ml-20">
                      <button type="submit" className="w-[80%]">
                        <div className="shadow border rounded-xl bg-yellow-400">
                          <div className="justify-center items-center">
                            <div className="p-5 text-xl font-semibold">
                              {"Login"}
                            </div>
                          </div>
                        </div>
                      </button>
                      <br />
                      <br />
                      <button
                        onClick={handleSignIn}
                        className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 488 512"
                          className="h-5 w-5 mr-2 fill-current"
                        >
                          <path d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                        </svg>
                        Sign in with Google
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
