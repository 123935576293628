import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setQuantity } from "../redux/actions/shopActions";
import axios from "axios";
import { API_BASE_URL } from "../network/API_BASE_URL";

export const ShopSearchDetailsPlan = ({translation}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { plans } = useSelector((state) => state.shop);
  const quantity = useSelector((state) => state.shop.quantity);

  let filteredData = plans.filter(
    (data, index) =>
      data.description.toLowerCase().indexOf(id.toLowerCase()) !== -1
  );

  const { t } = translation;

  let productId;
  let volumen;
  let days;
  let price;
  let category;
  let country;
  let network;
  if (filteredData.length > 0) {
    productId = filteredData[0].id;
    volumen = filteredData[0].company;
    days = filteredData[0].company2;
    price = Math.round(
      filteredData[0].priceesim / 10000 +
        (filteredData[0].priceesim / 10000) * (80 / 100)
    ).toFixed(2);
    category = filteredData[0].category;
    country = filteredData[0].category;
    network = filteredData[0].network;
  }

  const decrement = () => {
    if (quantity > 0) {
      dispatch(setQuantity(quantity - 1)); // Dispatch action to set quantity
    }
  };

  const increment = () => {
    dispatch(setQuantity(quantity + 1)); // Dispatch action to set quantity
  };

  const handleCancelOrderSuccess = () => {
    let newEvent = {
      name: "react_shop_detail_selected",
      action: "click",
      button: "checkout_button",
      id: id,
      view: "ShopDetails",
    };
    // props.analytics.logEvent(newEvent, "react_shop_checkout_button_click");

    let orderId = Date.now();
    let order = {
      orderId: orderId,
      productId: productId,
      count: quantity.toString(),
      price: price,
      filteredData: filteredData,
    };

    axios
      .post(API_BASE_URL + "/createorder", order)
      .then((response) => {
        console.log(response.data.url);
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming order:", error);
      });

    let checkout = {
      orderId: orderId,
      description: id,
      quantity: `${quantity}`,
      price: price,
      filteredData: filteredData,
    };
    axios
      .post(API_BASE_URL + "/checkout", { checkout })
      .then((response) => {
        // console.log(response.data.url);
        // window.open(response.data.url, "_self");
        window.open(response.data.href, "_self");
      })
      .catch((error) => {
        // Handle error
        console.error("Error confirming order:", error);
      });
  };

  const StringList = ({ string }) => {
    if (!string) return null;
    const items = string.split(", ");
    const bgColors = [
      "bg-red-500",
      "bg-green-500",
      "bg-blue-500",
      "bg-orange-500",
    ];
    return (
      <div>
        {items.map((item, index) => (
          <span
            key={index}
            className={`${
              bgColors[index % bgColors.length]
            } text-white text-xs font-semibold px-2 rounded-full mr-1`}
          >
            {item}
          </span>
        ))}
      </div>
    );
  };

  return (
    <section>
      <div className="w-full">
        <div className="flex flex-rows-2">
          <div className="ml-10 mt-10">
            <h1 className="text-xl lg:text-3xl font-bold">{t("shop_detail_view_label_first")}</h1>
          </div>
          <div className="ml-10 mt-10">
            <h1 className="text-xl lg:text-3xl">{volumen}</h1>
          </div>
          <div className="ml-10 mt-10">
            <h1 className="text-xl lg:text-3xl">{days}</h1>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="ml-10 mt-1 shadow border rounded-3xl bg-gray-100">
            <div className="p-5 lg:p-10">
              <h1 className="text-3xl font-semibold">{country}</h1>
              <div class="flex items-center space-x-2">
                <StringList string={network} />
              </div>
            </div>
          </div>
          <div className="ml-10 mt-2">
            <h1 className="text-sm font-normal">{t("shop_detail_view_label_second")}</h1>
          </div>
          <div className="ml-10 mt-2">
            <h1 className="text-sm font-normal">
              {`${t("shop_detail_view_label_fifth")} ${category} ${t("shop_detail_view_label_sixth")}`}
              {/* {`Experience seamless connectivity in ${category} without worrying about costly roaming fees with our international eSIM. With unlimited access to 3G/4G/LTE Internet, staying connected is effortless and affordable. Getting started is as simple as making a purchase, receiving a QR code via email, and scanning it with your smartphone camera. Install the eSIM, and enjoy fast and stable Internet, ensuring you can easily keep in touch with family and friends throughout your travels. `} */}
            </h1>
          </div>
          <div className="m-10">
            <h1 className="text-3xl font-bold">{"USD$" + price}</h1>
          </div>
          <div className="m-10">
            <div className="flex justify-start items-start space-x-4">
              <button
                onClick={decrement}
                className="px-4 py-2 bg-gray-500 text-white rounded"
              >
                -
              </button>
              <input
                type="text"
                className="w-20 h-10 text-center border border-gray-300 rounded"
                value={quantity}
                readOnly
              />
              <button
                onClick={increment}
                className="px-4 py-2 bg-gray-500 text-white rounded"
              >
                +
              </button>
            </div>
          </div>
          <button className="m-5" onClick={handleCancelOrderSuccess}>
            <div className="shadow border rounded-3xl bg-orange-400">
              <div className="p-5 flex flex-row justify-center">
                <div className="text-xl font-semibold ml-2 mt-3">
                  {t("shop_detail_view_button_first")}
                </div>
                <div className="text-3xl font-semibold ml-2 mt-2">{"USD$"}</div>
                <div className="text-3xl font-semibold mt-2">
                  {(price * quantity).toFixed(2)}
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};
