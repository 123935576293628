export const CancellationView = ({ translation }) => {

  const { t } = translation;

  return (
    <section>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="max-w-3xl mx-auto p-4 bg-white shadow-md rounded-md">
          <h2 className="text-2xl font-bold mb-4">{t("cancellation_view_label_first")}</h2>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">{t("cancellation_view_label_second")}</h3>
            <p>
              {t("cancellation_view_label_third")}
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">{t("cancellation_view_label_fourth")}</h3>
            <p>
              {t("cancellation_view_label_fifth")}
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">
              {t("cancellation_view_label_sixth")}
            </h3>
            <p>
              {t("cancellation_view_label_seventh")}{" "}
              <a className="text-blue-700" style={{'text-decoration':'underline'}} href="mailto:help@esimflys.com">help@esimflys.com</a>{" "}
              {t("cancellation_view_label_eighth")}
            </p>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">{t("cancellation_view_label_nineth")}</h3>
            <p>
              {t("cancellation_view_label_tenth")}
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">{t("cancellation_view_text_first")}</h3>
            <p>
              {t("cancellation_view_text_second")}
            </p>
          </div>

          <div className="mt-6">
            <h3 className="text-lg font-semibold mb-2">{t("cancellation_view_text_first")}</h3>
            <p>
              If you have any questions or need further assistance regarding
              cancellations, please do not hesitate to reach out to us at{" "}
              <a className="text-blue-700" style={{'text-decoration':'underline'}} href="mailto:info@esimflys.com">info@esimflys.com.</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
