import React, { useState } from "react";
import { useSelector } from "react-redux";

export const ShopSearchSideBar = ({translation}) => {
  const [continent, setContinent] = useState("");
  const plans = useSelector((state) => state.shop.plans);
  const planByCountry = plans.filter((item) => item.category.length > 0 );
  const popularItems = plans.filter((item) => item.popular === "true");
  const regionalItems = [
    {"continent":"America"},
    {"continent":"Asia"},
    {"continent":"Europe"},
    {"continent":"Africa"},
    {"continent":"Australia"}
  ]

  const { t } = translation;

  return (
    <section>
      <div className="w-full">
        <div className="*:flex *:flex-col">
          <div className="text-center justify-center items-center mt-20">
            <CollapsibleItem
              title={t("shop_view_collapsible_first")}
              fa="fa fa-trophy"
              color="text-yellow-600"
            >
              <h1>Not available yet</h1>
            </CollapsibleItem>
            <CollapsibleItem
              title={t("shop_view_collapsible_second")}
              fa="fa fa-fire"
              color="text-red-600"
            >
              {popularItems.map((popular, index) => (
                <SideBarCheckbox key={index} country={popular.category} />
              ))}
            </CollapsibleItem>
            <CollapsibleItem
              title={t("shop_view_collapsible_third")}
              fa="fa fa-map"
              color="text-blue-600"
            >
              {regionalItems.map((regional, index) => (
                <SideBarCheckbox key={index} country={regional.continent} />
              ))}
            </CollapsibleItem>
            <CollapsibleItem
              title={t("shop_view_collapsible_fourth")}
              fa="fa fa-flag"
              color="text-green-600"
            >
              {planByCountry.map((popular, index) => (
                <SideBarCheckbox key={index} country={popular.category} />
              ))}
            </CollapsibleItem>
            <CollapsibleItem
              title={t("shop_view_collapsible_fifth")}
              fa="fa fa-tag"
              color="text-orange-500"
            >
              <h1>Not available yet</h1>
            </CollapsibleItem>
          </div>
        </div>
      </div>
    </section>
  );
};

const CollapsibleItem = ({ title, fa, color, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="w-[80%] border border-white rounded-lg mb-4">
      <div
        className="border-b p-4 cursor-pointer flex justify-between items-center bg-white border rounded"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className={`text-lg font-semibold mr-4 ${color}`}>
          <i className={`${fa}`} />
        </h2>
        <h2 className="text-lg font-semibold mr-4">{title}</h2>
        <i className={`fa ${isOpen ? "fa-chevron-up" : "fa-chevron-down"}`} />
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

const SideBarCheckbox = ({ country }) => {
  const [isChecked1, setIsChecked1] = useState(false);

  const handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setIsChecked1(!isChecked1);
    }
  };
  const handleContainerClick = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      setIsChecked1(!isChecked1);
    }
  };

  return (
    <form>
      <div
        className="flex items-center"
        onClick={() => handleContainerClick(1)}
      >
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-blue-600"
          checked={isChecked1}
          onChange={() => handleCheckboxChange(1)}
        />
        <label className="ml-2 text-gray-700">{country}</label>
      </div>
    </form>
  );
};
