import { Header } from "../Header";
import { API_BASE_URL } from "../network/API_BASE_URL";

export const AboutUsView = ({translation}) => {

  const { t } = translation; 

  return (
    <section>
      <div className=" py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8 lg:mb-12">
          <img 
              src={`${API_BASE_URL}/images/AboutUs.jpg`}               
              alt="Beach Scene" className="rounded-lg shadow-lg mx-auto mb-6 lg:w-3/4 lg:mx-0" />
        </div>
        <div>
          <h1 className="text-3xl font-semibold text-gray-800 mb-6">{t("about_view_label_first")}</h1>
          <p className="text-lg text-gray-700 mb-6">
            {t("about_view_label_second")}
          </p>
          <p className="text-lg text-gray-700 mb-6">
            {t("about_view_label_third")}
          </p>
          <p className="text-lg text-gray-700 mb-6">
            {t("about_view_label_fourth")}  
          </p>
          <p className="text-lg text-gray-700 mb-6">
            {t("about_view_label_fifth")}
          </p>
        </div>
      </div>
    </div>
    </section>
  );
};
