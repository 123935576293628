import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './components/redux/store/store';
import "./styles/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppWithRedux = (
  <Provider store={store}>
    <Router>
      <App/>
    </Router>
  </Provider>
);

root.render(AppWithRedux);