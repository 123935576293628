import { API_BASE_URL } from "./network/API_BASE_URL";
export const HomePlans = () => {
  const tableInfo = [
    {
      id: "1",
      title: "eSim - Dominican Republic",
      description: "Unlimited Data",
      price: "$29 USD",
      priceSubTitle: "5 Days Unlimited Data",
      image: "dominican_republic.jpg",
    },
    {
      id: "2",
      title: "eSim - United States",
      description: "Unlimited Data",
      price: "$29 USD",
      priceSubTitle: "5 Days Unlimited Data",
      image: "united_state.JPG",
    },
    {
      id: "3",
      title: "eSim - Europe",
      description: "Unlimited Data",
      price: "$29 USD",
      priceSubTitle: "5 Days Unlimited Data",
      image: "europe.jpg",
    },
  
  ];
  return (
    <div class="flex flex-wrap ">
      {tableInfo.map((table) => (
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3">
          <div class="bg-white m-6 border rounded-3xl overflow-hidden shadow">
            <div class="flex flex-col">
              <div class="shadow">
                <img src={API_BASE_URL+'/images/'+table.image} />
              </div>
              <div className="p-6">
                <h4 className="font-bold text-gray-600">
                  {table.title}
                </h4>
                <div className="text-gray-400">{table.description}</div>
                <div className="flex flex-row">
                  <div className="font-bold text-gray-600">{table.price}</div>
                  &nbsp;
                  <div className="text-sm text-gray-400 justify-center items-center">
                    {table.priceSubTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
