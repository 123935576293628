import { FrontPage } from "../components/FrontPage";
import { Search } from "../components/Search";
import { HomePlans } from "../components/HomePlans";
import { Header } from "../components/Header";

export const Home = ({translation}) => {
    return (
        <section>
            <div>
                <FrontPage
                    translation={translation}
                />
                <HomePlans />
                <Search />
            </div>
        </section>
    );
}