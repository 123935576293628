import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { API_BASE_URL } from "./network/API_BASE_URL";

export const Header = ({ translation, analytics }) => {
  const [logged, setLogged] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { t, i18n } = translation;

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("token").length > 0) {
        setLogged(true);
      }
    }
  }, []);

  const logOut = (event) => {
    event.preventDefault();
    localStorage.removeItem("token");
    window.location.reload();
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click occurred outside of the dropdown, close it
      setDropdownVisible(false);
    }
  };

  const changeLanguage = (lng) => {
    console.log("here")
    i18n.changeLanguage(lng);
    setDropdownVisible(false);
  };

  return (
    <section>
      <div className="w-full">
        <div className="flex justify-between tall-sm:gap-10 bg-warning-500">
          <div className="self-start">
            <Link to="/">
              <img
                width={"70px"}
                src={API_BASE_URL + "/images/logo-transparent.png"}
                alt="esim Dominican Republic"
              />
            </Link>
          </div>
          <div className="hidden lg:m-5 lg:self-start lg:block">
            <span className="font-bold col-span-2">
              WhatsApp +1&nbsp;(809)&nbsp;883-2849
            </span>
          </div>
          {logged ? (
            <div>
              <div className="md:flex md:flex-row mr-5">
                <div className="hidden lg:m-5 lg:self-start lg:block">
                  <Link to="/help" className="font-bold">
                    {t("header_view_label_first")}
                  </Link>
                </div>
                <div className="hidden lg:m-5 lg:self-start lg:block">
                  <Link to="/about" className="font-bold">
                    {t("header_view_label_second")}
                  </Link>
                </div>
                <div className="hidden lg:m-5 lg:self-start lg:block">
                  <Link to="/message" className="font-bold">
                    Message
                  </Link>
                </div>
                <div className="hidden lg:m-5 lg:self-start lg:block">
                  <div className="relative" ref={dropdownRef}>
                    <button
                      className="font-bold"
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                      Profile
                    </button>
                    {dropdownVisible && (
                      <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                        <Link
                          to="/account"
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          Account
                        </Link>
                        <Link
                          to="/account"
                          onClick={logOut}
                          className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                        >
                          Logout
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="m-5 lg:hidden">
                <div className="relative" ref={dropdownRef}>
                  <button
                    className="font-bold"
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                  >
                    <i className="fa fa-bars text-2xl" />
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                      <Link
                        to="/shop"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Shop
                      </Link>
                      <Link
                        to="/help"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {t("header_view_label_first")}
                      </Link>
                      <Link
                        to="/about"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        {t("header_view_label_second")}
                      </Link>
                      <Link
                        to="/message"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Message
                      </Link>
                      <Link
                        to="/account"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Account
                      </Link>
                      <Link
                        to="/account"
                        onClick={logOut}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Logout
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="md:flex md:flex-row mr-5">
                <div className="hidden lg:self-start lg:block lg:m-5">
                  <Link to="/help" className="font-bold">
                    {t("header_view_label_first")}
                  </Link>
                </div>
                <div className="hidden lg:self-start lg:block lg:m-5">
                  <Link to="/about" className="font-bold">
                    {t("header_view_label_second")}
                  </Link>
                </div>
                <div className="hidden lg:self-start lg:block lg:m-5">
                  <Link to="/signup" className="font-bold">
                  {t("header_view_label_third")}
                  </Link>
                </div>
                <div className="hidden lg:self-start lg:block lg:m-5">
                  <Link to="/login" className="font-bold">
                    {t("header_view_label_fourth")}
                  </Link>                  
                </div>
                <div className="flex flex-row items-center">
                  <div>
                    <div className="relative block px-4 py-2 font-header text-white hover:bg-header-500" ref={dropdownRef}>
                      <button
                        className="font-bold"
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                      >
                        <i className="fa fa-language text-2xl" />
                      </button>
                      {dropdownVisible && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                          <div>
                            <button
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              onClick={() => changeLanguage("en")}
                            >
                              English
                            </button>
                          </div>
                          <div>
                            <button
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              onClick={() => changeLanguage("es")}
                            >
                              Español
                            </button>
                          </div>
                          <div>
                            <button
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              onClick={() => changeLanguage("de")}
                            >
                              Deutsch
                            </button>
                          </div>
                          <div>
                            <button
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              onClick={() => changeLanguage("fr")}
                            >
                              France
                            </button>
                          </div>
                          <div>
                            <button
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              onClick={() => changeLanguage("it")}
                            >
                              Italian
                            </button>
                          </div>
                          <div>
                            <button
                              className="block px-16 py-2 text-gray-800 hover:bg-gray-200"
                              onClick={() => changeLanguage("pt")}
                            >
                              Portuguese
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  </div>

              </div>
              <div className="m-5 lg:hidden">
                <div className="relative" ref={dropdownRef}>
                  <button
                    className="font-bold"
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                  >
                    <i className="fa fa-bars text-2xl" />
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
                      <Link
                        to="/shop"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Shop
                      </Link>
                      <Link
                        to="/help"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Help
                      </Link>
                      <Link
                        to="/about"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        About
                      </Link>
                      <Link
                        to="/signUp"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Sign Up
                      </Link>
                      <Link
                        to="/login"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Login
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
