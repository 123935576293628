import { useParams } from "react-router-dom";
import { API_BASE_URL } from "../network/API_BASE_URL";
import { useDispatch, useSelector } from "react-redux";

export const ShopSearchDetailsList = ({translation}) => {
  const { id } = useParams();
  const { plans } = useSelector((state) => state.shop);

  let filteredData = plans.filter(
    (data, index) =>
      data.description.toLowerCase().indexOf(id.toLowerCase()) !== -1
  );

  const { t } = translation;

  let image;
  let country;
  let network;
  if (filteredData.length > 0) {
    image = filteredData[0].category+".jpg";
    country = filteredData[0].category
    network = filteredData[0].network
  }

  const StringList = ({ string }) => {
    if (!string) return null;
    const items = string.split(', ');
    const bgColors = ['bg-red-500', 'bg-green-500', 'bg-blue-500', 'bg-orange-500'];  
    return (
      <div>
        {items.map((item, index) => (
          <span key={index} className={`${bgColors[index % bgColors.length]} text-white text-xs font-semibold px-2 rounded-full mr-1`}>{item}</span>
        ))}
      </div>
    );
  };
  const StringNetworkList = ({ string }) => {
    if (!string) return null;
    const items = string.split(', ');
    return (
      <div>
        {items.map((item, index) => (
          <span key={index} className={`text-black font-normal mr-1`}>{item}</span>
        ))}
      </div>
    );
  };
  return (
    <section>
      <div className="w-full">
        <div className="flex flex-rows-2 flex-col">
          <div className="ml-10 mt-10 mb-2">
            <img width={'400px'} height={'400px'}  alt="MainPicture" className="border rounded-3xl" src={API_BASE_URL+"/images/" + image}/>
          </div>
          <div className="w-full">
            <div className="flex flex-rows-3 flex-col">
              <div className="ml-10 mt-1 shadow border rounded-3xl bg-gray-100">
                <div className="p-10">
                  <h1 className="text-3xl font-semibold">{country}</h1>
                  <div class="flex items-center space-x-2">
                    <StringList string={network} />
                  </div>
                </div>
              </div>
              <div className="ml-10 mt-1 shadow border rounded-3xl bg-white">
                <div className="p-3">
                  <h1 className="text-lg font-semibold">
                    {t("shop_detail_view_label_fourth")}
                  </h1>
                </div>
              </div>
              <div className="flex flex-cols-2">
                <div className="w-1/2">
                  <div className="ml-10 mt-1 shadow border rounded-3xl">
                    <div className="p-5">
                      <div className="flex flex-row">
                        <ul className="list-disc">
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">{t("shop_detail_item_left_view_label_first")}</div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_first")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_second")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_second")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_third")}
                              </div>
                              <div>&nbsp;</div>
                              {t("shop_detail_item_left_view_text_third")}
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_fourth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_fourth")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_fifth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_fifth")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">{t("shop_detail_item_left_view_label_sixth")}</div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_sixth")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">{t("shop_detail_item_left_view_label_seventh")}</div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_seventh")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_eighth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_left_view_text_eighth")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_nineth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>
                              {t("shop_detail_item_left_view_text_nineth")}
                              </div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_left_view_label_tenth")}
                              </div>
                              <div>&nbsp;</div>
                              <div className="text-left">
                              {t("shop_detail_item_left_view_text_tenth")}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/2">
                  <div className="ml-1 mt-1 shadow border rounded-3xl">
                    <div className="p-5">
                      <div className="flex flex-row">
                        <ul className="list-disc">
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">{("Network")}</div>
                              <div>&nbsp;</div>
                              {/* <div>{"Claro / Altice (Orange)"}</div> */}
                              <StringNetworkList string={network} />
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">{t("shop_detail_item_right_view_label_second")}</div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_right_view_text_second")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_right_view_label_third")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_right_view_text_third")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_right_view_label_fourth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{t("shop_detail_item_right_view_text_fourth")}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_right_view_label_fifth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>{"eSIM"}</div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_right_view_label_sixth")}
                              </div>
                              <div>&nbsp;</div>
                              <div>
                              {t("shop_detail_item_right_view_text_sixth")}
                              </div>
                            </div>
                          </li>
                          <li className="text-sm">
                            <div className="flex flex-row">
                              <div className="font-semibold">
                              {t("shop_detail_item_right_view_label_seventh")}
                              </div>
                              <div>&nbsp;</div>
                              <div>
                              {t("shop_detail_item_right_view_text_seventh")}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
