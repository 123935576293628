import { fetchPlans } from "../../network/fetchPlans";

export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILURE = "FETCH_PLANS_FAILURE";
export const SET_QUANTITY = "SET_QUANTITY";
export const SET_EMAIL = "SET_EMAIL";

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email,
});

export const setQuantity = (quantity) => ({
  type: SET_QUANTITY,
  payload: quantity,
});

export const fetchPlansSuccess = (plans) => ({
  type: FETCH_PLANS_SUCCESS,
  payload: plans,
});

export const fetchPlansFailure = (error) => ({
  type: FETCH_PLANS_FAILURE,
  payload: error,
});

export const fetchPlansData = () => {
  return (dispatch) => {
    return fetchPlans()
      .then((responseData) => {
        dispatch(fetchPlansSuccess(responseData));
      })
      .catch((error) => {
        dispatch(fetchPlansFailure(error.message));
      });
  };
};
