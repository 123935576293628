import {
  SET_QUANTITY,
  FETCH_PLANS_SUCCESS,
  FETCH_PLANS_FAILURE,
  SET_EMAIL,
} from "../actions/shopActions";

const initialState = {
  plans: [],
  error: null,
  quantity: 1,
  email: "",
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload,
        error: null,
      };
    case FETCH_PLANS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_QUANTITY:
      return {
        ...state,
        quantity: action.payload,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};

export default shopReducer;
